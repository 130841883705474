.form-control:not(:placeholder-shown):valid {
  background-color: rgba(147, 231, 195, 0.1);
  border-color: #43c28e;
}

.form-control:not(:placeholder-shown):invalid {
  background-color: rgba(255, 139, 139, 0.1);
  border-color: #fa5252;
}

/* Use class-based approach for select styling */
.form-select.has-value {
  background-color: rgba(147, 231, 195, 0.1);
  border-color: #43c28e;
}

/* Add transition for smooth color changes */
.form-control, .form-select {
  transition: background-color 0.3s ease, border-color 0.3s ease;
} 
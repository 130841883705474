.custom-chartist-chart .ct-series-a .ct-line,
.custom-chartist-chart .ct-series-a .ct-point {
  stroke: #1f77b4; /* Color for Series 1 */
}

.custom-chartist-chart .ct-series-b .ct-line,
.custom-chartist-chart .ct-series-b .ct-point {
  stroke: #ff7f0e; /* Color for Series 2 */
}

.chart-legend {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.legend-item {
  margin-right: 20px;
  font-weight: bold;
}
